exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cosmetology-js": () => import("./../../../src/pages/cosmetology.js" /* webpackChunkName: "component---src-pages-cosmetology-js" */),
  "component---src-pages-food-and-supplements-js": () => import("./../../../src/pages/food-and-supplements.js" /* webpackChunkName: "component---src-pages-food-and-supplements-js" */),
  "component---src-pages-household-chemicals-js": () => import("./../../../src/pages/household-chemicals.js" /* webpackChunkName: "component---src-pages-household-chemicals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materials-js": () => import("./../../../src/pages/materials.js" /* webpackChunkName: "component---src-pages-materials-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-other-industries-js": () => import("./../../../src/pages/other-industries.js" /* webpackChunkName: "component---src-pages-other-industries-js" */),
  "component---src-pages-pharmacy-js": () => import("./../../../src/pages/pharmacy.js" /* webpackChunkName: "component---src-pages-pharmacy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-website-regulations-js": () => import("./../../../src/pages/website-regulations.js" /* webpackChunkName: "component---src-pages-website-regulations-js" */)
}

